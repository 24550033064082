import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch';
import "./Insights.css";


export default function Insights() {
    const [found, setFound] = useState(null);
    const [headline, setHeadline] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [mainBody, setMainBody] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [authorImage, setAuthorImage] = useState("");
    const [lastUpdate, setLastUpdate] = useState(null);

    const params = useParams();
    const algoliaAppId = process.env.REACT_APP_algoliaAppId;
    const algoliaApiKey = process.env.REACT_APP_algoliaApiKey;

    const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);
    // const searchClient = algoliasearch('0K9MUXZLQ5', 'e6e35f0afb6f89783df879a4c8ee0733')
    const index = searchClient.initIndex("market_content");
    index.findObject(hit => hit.contentSlug === params.slug)
        .then(obj => {
            console.log(JSON.stringify(obj.object.content));
            setFound(obj.object.contentSlug === params.slug);

            setHeadline(obj.object.content.headline);
            setMainBody(obj.object.content.mainBody);
            setLastUpdate(obj.object.lastUpdate);
            setBannerImage(obj.object.bannerImage);
            setAuthorImage(obj.object.authorImage);
            setAuthorName(obj.object.authorName);
        });


    console.log(mainBody);


    return (
        <>
            <NavBar />
            <div className='insights-container'>
                <div className='article-title'> <h1>{headline}</h1>
                </div>

                <div className='article-banner-img'>
                    <img className='article-img' src={bannerImage} alt="Girl in a jacket" width="100%" height="300"></img>
                </div>

                <div className='article-author-container'>
                    <img className='author-img' src={authorImage} alt="Blog Author"></img>
                    <div className='author-details'><p className='author-name'>{authorName}</p>
                    <p className='last-update'>{lastUpdate}</p></div>

                </div>

                <div className='article-content'>
                    {parse(mainBody)
                    }

                </div>

                <div className='article-tags'>

                </div>
            </div>
            <Footer />

        </>
    )


    // if (!found) {
    //     return (
    //         <div>
    //             <p>Page not found</p>
    //         </div>
    //     )

    // }



}
