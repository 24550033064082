import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterComp.css';


const RegisterComp = () => {
  const [email, setEmail] = useState("example@mail.com");
  const [password, setPassword] = useState("pass");
  const backendAPI = process.env.REACT_APP_backendAPI;
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    CreateUserId(email, password);
    navigate('/Login', { state: { } });
  };

  function CreateUserId(email, password) {
    const reponse = fetch(`${backendAPI}/CreateUserId`, {
      method: 'POST', // or 'GET', 'PUT', etc.
      headers: {
        'Content-Type': 'application/json',
        // Additional headers if needed
      },
      body: JSON.stringify({ "username": email, "password": password }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response
        console.log(data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }


  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid" alt="" />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 login-div">
            <form >
              <div className="form-outline mb-4">
               
                <label htmlFor="email">
                  <input type="email" id="form3Example3"  value={email} onChange={handleEmailChange}
                    className="form-control form-control-lg" placeholder="Email address" style={{ opacity: 0.4 }} />
                </label>
              </div>
              <div className="form-outline mb-3">
               
                <label htmlFor='email'>
                  <input type="password" id="form3Example4" value={password} onChange={handlePasswordChange }
                    className="form-control form-control-lg" placeholder="New password" style={{ opacity: 0.4 }} />
                </label>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                {/* Checkbox */}
                <div className="form-check mb-0">
                  <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
                <a href="#!" className="text-body">Forgot password?</a>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button type="submit" className="btn btn-primary btn-lg"
                style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                onClick={handleSubmit}>
                  Register
                  </button>
                <p className="small fw-bold mt-2 pt-1 mb-0">Already have an account? <a href="#!"
                  className="link-danger">Login</a></p>
              </div>
            </form>
            <script src="RegisterAPI.js"></script>
          </div>
        </div>
      </div>
    </section>
  );

}




export default RegisterComp;